import { render, staticRenderFns } from "./Answer.vue?vue&type=template&id=1acae2e2&scoped=true&"
import script from "./Answer.vue?vue&type=script&lang=js&"
export * from "./Answer.vue?vue&type=script&lang=js&"
import style0 from "./Answer.vue?vue&type=style&index=0&id=1acae2e2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1acae2e2",
  null
  
)

export default component.exports