<template>
    <div class="p-3 t-wrapper" style="min-height: 100%">
        <div class="container w-100 h-100" :style="result_shown ? 'display: none' : 'display: block'">
            <div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                <h3 class="mm-font text-center">သင့်အတွက်ယနေ့ကံကြမ္မာ</h3>
                <div class="pane-line"></div>
                <h5 class="text-center mt-4 mb-4 mm-font">
                    အောက်ပါ တားရော့ကတ်ကို နှိပ်၍ သင့်အတွက်ဟောစာတမ်း ရယူပါ
                </h5>
                <section class="mt-4 d-flex flex-column align-items-center justify-content-center">
                    <img 
                        :src="card_img.src" 
                        alt="Card" 
                        class='tarot-card'
                        :style="shuffling ? 'cursor: default' : 'cursor: pointer'"
                        @click="shuffle"
                    />
                </section>
            </div>
        </div>
        <div class="container h-100" :style="result_shown ? 'display: block' : 'display: none'">
            <div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center pb-4">
                <img :src="result_img" class='tarot-card' alt="Result card"/>
                <h3 class="mm-font text-center mt-4 mb-2" style="font-weight: bold">{{this.result_title}}</h3>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <p class="mm-font mt-4" style="text-align: justify; word-break: break-all;word-spacing: -2px; font-size: 120%;">{{this.result_content}}</p>
                        </div>
                    </div>
                </div>
                <span class="mt-3 back-btn" @click="back">&#8592; back</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TarotShuffle",

        created() {
            this.paymentName = this.$store.state.paymentName;
            this.card_gif_path.src = require("@/images/tarot/shuffle.gif");
            this.ini_card_path.src = require("@/images/tarot/cards.png");
            this.card_img.src = require("@/images/tarot/cards.png");
        }, 

        data() {
            return {
                ini_card_path: new Image(),
                card_gif_path: new Image(),

                shuffling: false,
                after_result: false,
                result_shown: false,
                card_img: new Image(),
                result_img: null,
                result_title: "",
                result_content: "",
                timeoutId: null,
            }
        },

        methods: {
            async shuffle() {
                if(this.shuffling) return;

                try {
                  this.shuffling = true;
                  this.card_img = this.card_gif_path;

                  let content = await this.getTarotContent();
                  let result_img = require(`@/images/tarot/cards/${content.image}`);
                  this.timeoutId = setTimeout(() => {
                    this.showResult({
                      ...content.myan,
                      image: result_img
                    });
                    this.shuffling = false;
                  }, 3000);
                } catch (error) {
                  console.error("Error during shuffle:", error);
                  this.shuffling = false;
                }
            },

            back() {
                console.log("route parameter: ", this.$route.query.payment);
                this.$router.push({
                    name: "withPaymenthome",
                    params: {
                        payment: this.$route.query.payment
                    }
                });
            },

            async getTarotContent() {
                let rand_content_id = this.getRandomRange();
                return await (
                    await fetch(`https://1875baydin.com/api/tarot-contents/${rand_content_id}`, {method: "POST"})
                ).json();
            },

            getRandomRange() {
                let min = 1;
                let max = 22; // number of tarot contents here
                return parseInt(Math.random() * (max-min) + min);
            },

            showResult({title, image, content}) {
                this.result_img = image;
                this.result_title = title;
                this.result_content = content;
                this.result_shown = true;
            }
        },

        beforeUnmount() {
          if(this.timeoutId) {
            clearTimeout(this.timeoutId);
          }
        }
    }
</script>

<style>
    html, body {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        overflow: auto;
    }

    body {
        padding: 30px 20px; 
        scroll-behavior: smooth;
    }

    * {
        box-sizing: border-box;
        font-family: Arial, Helvetica, sans-serif;
    }

    .t-wrapper {
        background: #031d2e !important;
    }

    .t-wrapper * {
        color: white;
    }

    #canvas, #box_wrapper, .LayoutDefault__main {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    div.pane-line {
        width: 150px;
        height: 5px;
        border-radius: 30px;
        background: #ff7010 !important;
    }

    .tarot-card {
        width: 200px;
        height: auto;
        animation-name: zoom-in;
        animation-duration: .3s;
        animation-timing-function: linear;
    }

    .mm-font {
        font-family: 'Padauk', sans-serif;
        line-height: 200%;
    }

    .back-btn {
        font-weight: bold;
        font-size: 120%;
        background-color: #ff7010;
        color: white;
        padding: 10px 20px;
        border-radius: 30px;
        cursor: pointer;
    }

    @keyframes zoom-in {
        0% {
            transform: scale(0, 0);
        }

        100% {
            transform: scale(1, 1);
        }
    }
</style>
