import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import LoadScript from "vue-plugin-load-script";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from "moment";
import Meta from "vue-meta";
import dotenv from "dotenv";
// import VueCryptojs from "vue-cryptojs";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import "primeicons/primeicons.css";




// Vue.use(VueCryptojs);

dotenv.config();

Vue.config.productionTip = false;

Vue.use(LoadScript);
Vue.use(VueSweetalert2);
Vue.use(Meta);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueMaterial)

Vue.prototype.moment = moment;

Vue.loadScript("/js/kbzpay.js").then(() => {
    Vue.prototype.kbzpay = window.kbzpay;
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app");
});
