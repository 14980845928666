<template>
  <section class="featured-home-post">
    <section class="container mx-auto px-2">
      <div
        class="card p-3"
        style="border-color: #eb6224; border-radius: 20px"
      >
        <div class="text-center mb-4">
          <h4>
            သင့်မွေးနေ့ကို ရိုက်ထည့်၍ တစ်နှစ်စာဟောစာတမ်း ရယူပါ
          </h4>
        </div>

        <form class="space-y-4">
          <div class="d-flex align-items-center mb-3">
            <label class="form__label text-sm font-medium">အမည်</label>
            <div class="form__group">
              <input type="text" class="form__input p-2 rounded-md" v-model="newyearbaydin.name" />
            <div class="error__message" v-if="!newyearbaydin.name && errors.name">
              <i class="pi pi-exclamation-circle mr-1"></i>
              {{ errors.name }}
            </div>
            </div>
          </div>

          <div class="d-flex align-items-center mb-3">
            <label class="form__label text-sm font-medium text-nowrap">ဖုန်းနံပါတ်</label>
            <div class="form__group">
              <input type="number" class="form__input p-2 rounded-md" v-model="newyearbaydin.phone_number" @input="validateForm" placeholder="09xxxxxxxxx" />
              <div class="error__message" v-if="errors.phone_number">
                  <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                  {{ errors.phone_number }}
            </div>
            </div>
          </div>

          <div class="dob__form d-flex flex-column flex-md-row mb-3">
            <label class="text-sm text-nowrap font-medium w-25"
              >မွေးသက္ကရာဇ်</label
            >
            <div class="d-flex flex-wrap gap-15 left-15">
              <div class="flex-fill align-items-center">
                <label class="d-block text-sm font-weight-medium">ခုနှစ်</label>
                <div class="dropdown__container">
                  <select
                  class="select__form w-100 p-2 rounded-md"
                  v-model="newyearbaydin.year"
                >
                  <option disabled value="">ခုနှစ်</option>
                  <option v-for="year in years" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
                <i class="pi pi-chevron-down dropdown__icon"></i>
                </div>
              </div>
              <div class="flex-fill align-items-center">
                <label class="d-block text-sm font-weight-medium">လ</label>
                <div class="dropdown__container">
                  <select
                  class="select__form w-100 p-2 rounded-md"
                  v-model="newyearbaydin.month"
                >
                  <option disabled value="">လ</option>
                  <option
                    v-for="(month, index) in month_list"
                    :key="index"
                    :value="index + 1"
                  >
                    {{ month }}
                  </option>
                </select>
                <i class="pi pi-chevron-down dropdown__icon"></i>
                </div>
              </div>
            </div>
            <div class="d-flex flex-wrap gap-15 left-15"> 
                <div class="flex-fill align-items-center">
                  <label class="d-block text-sm font-weight-medium">ရက်</label>
                  <div class="dropdown__container">
                    <select
                      class="select__form w-100 p-2 rounded-md mr-3"
                      v-model="newyearbaydin.day"
                    >
                      <option disabled value="">ရက်</option>
                      <option v-for="i in 31" :key="i" :value="i">{{ i }}</option>
                    </select>
                    <i class="pi pi-chevron-down dropdown__icon"></i>
                  </div>
                </div>
                <div class="flex-fill align-items-center">
                  <label class="d-block text-sm font-weight-medium"
                    >နေ့နံ</label
                  >
                  <div class="dropdown__container">
                    <select
                      class="form__input w-100 p-2 rounded-md"
                      v-model="newyearbaydin.day_name"
                    >
                      <option disabled value="">နေ့နံ</option>
                      <option value="တနင်္ဂနွေ">တနင်္ဂနွေ</option>
                      <option value="တနင်္လာ">တနင်္လာ</option>
                      <option value="အင်္ဂါ">အင်္ဂါ</option>
                      <option value="ဗုဒ္ဓဟူး">ဗုဒ္ဓဟူး</option>
                      <option value="ကြာသပတေး">ကြာသပတေး</option>
                      <option value="သောကြာ">သောကြာ</option>
                      <option value="စနေ">စနေ</option>
                      <option value="ရာဟု">ရာဟု</option>
                    </select>
                    <i class="pi pi-chevron-down dropdown__icon"></i>
                  </div>
                </div>
            </div>

            <div class="error__message error__dob" v-if="(!newyearbaydin.year || !this.newyearbaydin.month || !this.newyearbaydin.day || !this.newyearbaydin.day_name) && errors.dob">
                <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                {{ errors.dob }}
            </div>
          </div>

          <p class="t_c_agreement">By continuing, you agree to <span @click.prevent="redirectTNC()">Terms and Conditions.</span></p>

          <button class="w-full bg-orange-500 text-white py-2 rounded-md text-nowrap" @click.prevent="paymentProvider()" :disabled="disabled">
            ရယူမည်
          </button>
        </form>
      </div>
    </section>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {HTTP, ONEYEAR} from "@core/lib/http-common";
import {checkFormModel, getFormData} from "@core/lib/form-data";
import {makePayment, onePay} from "../js/payment";
import {EventBus} from "../../public/js/eventBus";
import { validatePhoneNumber } from "../utils/validationPhone";

export default {
  name: `NewYearBaydin`,
  data() {
    return {
      actionBtnMethod: "",
      disabled: false,
      onepayPhoneNumber: "",
      years: [],
      month_list: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],

      newyearbaydin: {
        name: "",
        amount: 0,
        year: "",
        month: "",
        day: "",
        day_name: "",
        phone_number: "",
      },
      errors: {},
      isMM: false
    };
  },
  created() {
    // this.newyearbaydin.payment_method = this.$store.state.paymentName;
    this.actionBtnMethod = this.$store.state.paymentName;
    for (let i = 1950; i <= new Date().getFullYear(); i++) {
      this.years.push(i);
    }

    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";

    if (this.actionBtnMethod == "onepay") {
      this.newyearbaydin.amount = 3000;
    } else if (this.actionBtnMethod == "mpitesan") {
      this.newyearbaydin.amount = 3000;
    } else if (this.actionBtnMethod == "citizenpay") {
      this.newyearbaydin.amount = 3000;
    } else {
      this.newyearbaydin.amount = 3000;
    }

    ONEYEAR.post("clickCount", {
      payment_method:
        this.$store.state.paymentName == " "
          ? "KBZPay"
          : this.$store.state.paymentName,
    });
  },
  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),
  },
  mounted(){
    this.getLocale();
  },  
  methods: {
    getLocale() {
        window.ma.callNativeAPI("getLocale", {}, res => {
        this.isMM = res.language === "my-MM";
      })
    },

    redirectTNC() {
      this.$router.push('/category/terms_and_conditions')
    },

    paymentProvider() {
      this.validateForm();
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "bppay":
          this.bpPay();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        // case "citizenpay":
        //   this.citizenPayProvicer();
        // break;
        default:
          this.precreate();
          break;
      }
    },

    precreate() {
      if (checkFormModel(this.newyearbaydin)) {
        if(this.newyearbaydin.phone_number.length < 8 || this.newyearbaydin.phone_number.length > 11) {
          this.$swal("Oops", "ဖုန်းနံပါတ် ပြည့်စုံစွာ ထည့်သွင်းပေးပါ။", "error");
          return;
        }
        this.disabled = true;

        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 11001101,
          amount: this.newyearbaydin.amount,
          category_name: "newyearbaydin",
        })
          .then((response) => {
            const res = response.data;
            let formdata = getFormData(this.newyearbaydin);
            formdata.set("amount", res.amount);
            formdata.append("user_id", res.user_id);
            formdata.append("auth1", "1875BaydinUser");
            formdata.append("auth2", "1875BaydinUserP@ssword");
            formdata.append("payment_method", "KBZPAY");
            formdata.append("order_id", res.order_id);
            formdata.append(
              "kpay_number",
              this.getResponse.customer_info.msisdn
            );

            ONEYEAR.post("oneyear", formdata)
              .then((response) => {
		
                this.res_status = response.data.order_status;

                // new kbzpay mini_app api
                window.ma.callNativeAPI('startPay', {
                  prepayId: res.prepay_id,
                  orderInfo: res.order_info,
                  sign: res.sign_app,
                  signType: 'SHA256',
                  disableNewCheckout: 'false',
                  tradeType: "MINIAPP"
                  }, res => {
                  console.log("startPay api res: ", res);
                  if (res.resultCode == 1) {
                    EventBus.$emit("clear-cache");
                    console.log("startpaysucess")
                    if (!this.isMM) {
                      this.$swal(
                        "Success",
                        "Purchased. Please contact 09456880335 if you have any problems.",
                        "success"
                      );
                      this.$router.push({ name: "home" });
                    }
                    if (this.isMM) {
                      this.$swal(
                        "အောင်မြင်ပါသည်",
                        "ဝယ်ယူပြီးပါပြီ။ အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါ။",
                        "success"
                      );
                      this.$router.push({ name: "home" });
                    }
                  }else {
                    if (!this.isMM) {
                      this.$swal("Fail", "Please try again", "error");
                      this.disabled = false;
                    }
                    if (this.isMM) {
                      this.$swal(
                        "မအောင်မြင်ပါ",
                        "ထပ်မံကြိုးစားပေးပါ",
                        "error"
                      );
                      this.disabled = false;
                    }
                  }
                });
                // this.kbzpay.startPay(
                //   res.prepay_id,
                //   res.order_info,
                //   res.sign_app,
                //   ({ resultCode, lang }) => {
                //     if (resultCode == 1) {
                //       if (lang == "en") {
                //         this.$swal(
                //           "Success",
                //           "Your Purchase Success",
                //           "success"
                //         );
                //         this.$router.push({ name: "home" });
                //       }
                //       if (lang == "my") {
                //         this.$swal(
                //           "အောင်မြင်ပါသည်",
                //           "ဝယ်ယူပြီးပါပြီ",
                //           "success"
                //         );
                //         this.$router.push({ name: "home" });
                //       }
                //     }
                //     if (resultCode == 2) {
                //       if (lang == "en") {
                //         this.$swal("Failed", "Your Purchase Failed", "error");
                //         this.disabled = false;
                //       }
                //       if (lang == "my") {
                //         this.$swal(
                //           "မအောင်မြင်ပါ",
                //           "ထပ်မံကြိုးစားပေးပါ",
                //           "error"
                //         );
                //         this.disabled = false;
                //       }
                //     }
                //   },
                //   () => {
                //     this.$swal("Failed", "Your Purchase Failed", "error");
                //     this.disabled = false;
                //   }
                // );
              })
              .catch(() => {
                this.$swal("Failed", "Somethinggg Went Wrong!", "error");
                this.disabled = false;
              });
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        if(this.isMM) {
          this.$swal(
          "Oops",
          "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
          "error"
        );
        } else {
          this.$swal(
          "Oops",
          "You need to fill all fields.",
          "error"
        );
        }
      }
    },

    async onePayProvider() {
      if (checkFormModel(this.newyearbaydin)) {
        this.disabled = true;
        let formdata = getFormData(this.newyearbaydin);
        formdata.append("user_id", this.onepayPhoneNumber);
        formdata.append("payment_method", "OnePay");
        let cdata = {
          category_id: 11001101,
          amount: this.newyearbaydin.amount,
          category_name: "newyearbaydin",
          phone_number: this.onepayPhoneNumber,
        };

        await onePay([
          "oneyear",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.newyearbaydin.amount,
          "newyearbaydin",
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async bpPay() {
      if (checkFormModel(this.newyearbaydin)) {
        this.disabled = true;

        let formdata = getFormData(this.newyearbaydin);
        formdata.append("user_id", this.newyearbaydin.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 11001101,
          amount: this.newyearbaydin.amount,
          category_name: "newyearbaydin",
        };
        await makePayment([
          "oneyear",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.newyearbaydin.amount,
          "newyearbaydin",
        ])
          .then((data) => {
            // console.log(data)
            if (data.status == 200) {
              // console.log(data);
              this.$swal({
                html: "ငွေ​ပေး​ချေမှု​အောင်မြင်ပြီးပါက ဖြည့်ထား​သော ဖုန်းနံပါတ်သို့ ဗေဒင်အ​ဟောကြည့်ရှုရန် link ကို SMS ​ပေးပို့​ပေးပါမည်။",
                icon: "info",
              }).then(() => {
                this.$store.state.order_id = data.order_id;
                this.$store.state.amount = data.amount;
                this.$router.push({
                  name: "paymentPortalV3",
                });
              });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            // console.log(error)
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      if (checkFormModel(this.newyearbaydin)) {
        this.disabled = true;
        let formdata = getFormData(this.newyearbaydin);
        formdata.append("user_id", this.newyearbaydin.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 11001101,
          amount: this.newyearbaydin.amount,
          category_name: "newyearbaydin",
        };
        await makePayment([
          "oneyear",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.newyearbaydin.amount,
          "newyearbaydin",
        ])
          .then((data) => {
            // console.log(data)
            if (data.status == 200) {
              // console.log(data);
              this.$swal({
                html: "ငွေ​ပေး​ချေမှု​အောင်မြင်ပြီးပါက ဖြည့်ထား​သော ဖုန်းနံပါတ်သို့ ဗေဒင်အ​ဟောကြည့်ရှုရန် link ကို SMS ​ပေးပို့​ပေးပါမည်။",
                icon: "info",
              }).then(() => {
                this.$store.state.order_id = data.order_id;
                this.$store.state.amount = data.amount;
                this.$router.push({
                  name: "paymentPortal",
                });
              });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            // console.log(error)
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    validateForm() {
      this.errors = {};

      if(this.newyearbaydin.name === '' || !this.newyearbaydin.name) this.errors.name = 'အမည်ထည့်ရန်လိုအပ်ပါသည်။'
      if((this.newyearbaydin.year === '' || !this.newyearbaydin.year) || 
        (this.newyearbaydin.month === '' || !this.newyearbaydin.month) || 
        (this.newyearbaydin.day === '' || !this.newyearbaydin.day) ||
        (this.newyearbaydin.day_name === '' || !this.newyearbaydin.day_name))
        this.errors.dob = "မွေးသက္ကရာဇ်ထည့်ရန်လိုအပ်ပါသည်။"
      this.errors.phone_number = validatePhoneNumber(this.newyearbaydin.phone_number)
  },
  },
};
</script>

<style scoped>
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 15px;
  margin: 0;
}

label {
  color: #eb6224;
}

.tarot__form {
  gap: 20px;
}

.dob__form {
  gap: 4px;
}

.form__label {
  width: 25%;
}

.form__group {
  width: 75%;
}

.form__input {
  border: 1px solid #eb6224;
}

.select__form {
  border:1px solid #eb6224;
}

p,
h1,
h2,
h4,
h5,
h6 {
  margin: 0;
}

h4 {
    font-size: 16px;
    line-height: 20px;
    color: #eb6224;
    font-weight: 300;
}

img {
  max-width: 100%;
}

.slider {
  margin-left: -20px;
}

.option {
  color: #eb6224;
  text-align: center;
  width: 30px;
  font-weight: 300;
}

.container {
  margin-top: 50px;
}

select.form-control {
  cursor: pointer;
  width: 60px;
  font-size: 15px;
  transform: translateY(-1px);
}

input {
  background: #fff;
}

button {
  height: 42px;
  width: 115px;
  border-radius: 7.5cm;
  display: inline-flex;
  border: none;
  font-weight: 200;
  font-size: 13px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 20px;
  background: #eb6224;
  margin-right: 15px;
}

.dropdown__icon {
  color: #000 !important;
  font-size: 14px;
}

@media (max-width: 590px) {
  form {
    padding-top: 30px;
    padding-bottom: 33px;
  }

  h2 {
    font-size: 14px;
  }

}

.error__message {
	margin-top: 0.5em !important;
}

.error__dob {
  margin-left: 15px !important;
}

@media screen and (max-width: 480px) {
  .form__label {
  width: 30%;
}

.form__group {
  width: 70%;
}
}
</style>
