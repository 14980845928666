<template>
    <div class="container col d-flex flex-column align-items-center mt-2">
        <div v-for="(tarot, index) in tarots" :key="index" class="card mb-2" style="width: 18rem;">
            <h5 class="card-header text-center">တားရော့</h5>
            <div class="card-body">
                <div><strong>Price - </strong>{{ tarot.price }}</div>
                <div><strong>About - </strong>{{ tarot.about }}</div>
                <button class="small-btn">View Detail</button>
            </div>
        </div>

        <div v-for="(clairvoyance, index) in clairvoyances" :key="`clairvoyance-${index}`" class="card mb-2" style="width: 18rem;">
            <h5 class="card-header text-center">အကြားအမြင်</h5>
            <div class="card-body">
                <div><strong>Price - </strong>{{ clairvoyance.price }}</div>
                <div><strong>About - </strong>{{ clairvoyance.about }}</div>
                <button class="small-btn">View Detail</button>
            </div>
        </div>

        <div v-for="(starBaydin, index) in starBaydins" :key="`starBaydin-${index}`" class="card mb-2" style="width: 18rem;">
            <h5 class="card-header text-center">လက္ခဏာ</h5>
            <div class="card-body">
                <div><strong>Price - </strong>{{ starBaydin.price }}</div>
                <div><strong>About - </strong>{{ starBaydin.about }}</div>
                <button class="small-btn">View Detail</button>
            </div>
        </div>

        <div v-for="(childName, index) in childNaming" :key="`childName-${index}`" class="card mb-2" style="width: 18rem;">
            <h5 class="card-header text-center">ကလေး အမည်ပေး</h5>
            <div class="card-body">
                <div><strong>Father Name - </strong>{{ childName.father_name }}</div>
                <div><strong>Mother Name - </strong>{{ childName.mother_name }}</div>
                <div><strong>Birth Date - </strong>{{ childName.birth_date }}</div>
                <button class="small-btn">View Detail</button>
            </div>
        </div>

        <div v-for="(loveBaydin, index) in loveBaydin" :key="`loveBaydin-${index}`" class="card mb-2" style="width: 18rem;">
            <h5 class="card-header text-center">အချစ်ရေး အဟော</h5>
            <div class="card-body">
                <div><strong>Girl Name - </strong>{{ loveBaydin.g_name }}</div>
                <div><strong>Boy Name - </strong>{{ loveBaydin.b_name }}</div>
                <div><strong>Girl Birth Date - </strong>{{ loveBaydin.g_birth_date }}</div>
                <button class="small-btn">View Detail</button>
            </div>
        </div>

        <div v-for="(callService, index) in callService" :key="`callService-${index}`" class="card mb-2" style="width: 18rem;">
            <h5 class="card-header text-center">ဖုန်းခေါ်မယ်</h5>
            <div class="card-body">
                <div><strong>Name - </strong>{{ callService.name }}</div>
                <div><strong>Birth Date - </strong>{{ callService.birth_date }}</div>
                <div><strong>About - </strong>{{ callService.about }}</div>
                <button class="small-btn">View Detail</button>
            </div>
        </div>

    </div>
</template>

<style scoped>
button.small-btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}
</style>

<script>
import { mapGetters } from "vuex";
import { HTTP } from "@core/lib/http-common";

export default {
  data() {
    return {
      tarots: [],
      clairvoyances: [],
      starBaydins: [],
      childNaming: [],
      loveBaydin: [],
      callService: [],
    };
  },
  computed: {
    ...mapGetters({
      getOpenID: "userStore/getOpenID"
    })
  },
  async created() {
    try {
    //   const openID = this.getOpenID; 
      const openID = 'b15bf6eb7ba121785c2576bf15ad17bcd149927fac2716412200191f94879549'; 
      const response = await HTTP.get(`questions/${openID}`); 
      this.tarots = response.data.data.tarots;
      this.clairvoyances = response.data.data.clairvoyances;
      this.starBaydins = response.data.data.starBaydins;
      this.childNaming = response.data.data.childNaming;
      this.loveBaydin = response.data.data.loveBaydin;
      this.callService = response.data.data.callService;
      console.log(response.data.data); 
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  },
};
</script>