<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="entry-content">
            <div class="woocommerce">
              <div class="woocommerce-MyAccount-content">
                <form role="form" id="tarot-form">
                  <p class="mm-font __mm" align="center">
                    
                    အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                  </p>
                  <div class="form-group">
                    <span class="mm-font __mm __mm">အမည်</span>
                    <input
                      type="text"
                      class="form-control"
                      required
                      v-model="tarot_shuffle.name"
                      name="name"
                    />
                    <div class="error__message" v-if="!tarot_shuffle.name && errors.name">
                      <i class="pi pi-exclamation-circle mr-1"></i>
                      {{ errors.name }}
                    </div>
                  </div>
                  <div class="form-group">
                    <span class="mm-font __mm __mm">ဖုန်းနံပါတ်</span>
                    <input
                      type="number"
                      class="form-control"
                      required
                      v-model="tarot_shuffle.phone"
                      name="name"
                      placeholder="09xxxxxxxxx"
                      @input="validateForm"
                    />
                    <div class="error__message" v-if="errors.phone">
                      <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                      {{ errors.phone }}
                    </div>
                  </div>


                  <p class="t_c_agreement">By continuing, you agree to <span @click.prevent="redirectTNC()">Terms and Conditions.</span></p>
                  
                  <p>
                    2000 ကျပ် ကျသင့်ပါမည်။ <br />အဆင်မပြေမှုများရှိပါက
                    09456880335 သို့ဆက်သွယ်ပါရန်။
                  </p>
                  <p>
                    <input
                      @click.prevent="handleSubmit()"
                      type="submit"
                      :disabled="disabled"
                      class="woocommerce-Button button mm-font __mm"
                      value="အတည်ပြုမည်"
                    />
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {checkFormModel, getFormData} from "@core/lib/form-data";
import {Horo, HTTP} from "../../core/lib/http-common";
import {mapGetters} from "vuex";
import {citizenPay, makePayment, onePay} from "../js/payment";
import {EventBus} from "../../public/js/eventBus";
import { validatePhoneNumber } from "../utils/validationPhone";

export default {
  name: "TarotShuffleForm",

  data() {
    return {
      disabled: false,
      actionBtnMethod: "",
      tarot_shuffle: {
        name: "",
        phone: "",
        category_id: "3478",
        category_name: "tarot_shuffle",
        price: 1,
        order_id: "0",
      },
      errors: {},
      isMM: false
    };
  },

  mounted() {
    this.getLocale();
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";
  },

  created() {
    this.actionBtnMethod = this.$store.state.paymentName;
  },

  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),
  },

  methods: {
    getLocale() {
        window.ma.callNativeAPI("getLocale", {}, res => {
        this.isMM = res.language === "my-MM";
      })
    },

    handleSubmit() {
      this.validateForm();
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        case "citizenpay":
          this.citizenPayProvider();
          break;
        case "bppay":
          this.bpPay();
          break;
        default:
          this.precreate();
          break;
      }
    },

    redirectTNC() {
        this.$router.push('/category/terms_and_conditions')
    },

    async precreate() {
      if (checkFormModel(this.tarot_shuffle)) {
        this.disabled = true;
        let form_data = getFormData(this.tarot_shuffle);
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        console.log("user token: ", this.getUserToken);
        try {
          let backend_res = (
            await HTTP.post("subscription/precreate", {
              category_id: this.tarot_shuffle.category_id,
              category_name: this.tarot_shuffle.category_name,
            })
          ).data;

          form_data.append("payment_method", "KBZPay");
          form_data.append("srcFrom", "KBZPay");
          form_data.set("price", backend_res.amount);
          form_data.set("order_id", backend_res.order_id);

          let horo_res = (
            await Horo.post("1875/tarot-shuffle/horoscope", form_data)
          ).data;

          console.log("backend res: ",backend_res);
          console.log("horo res: ",horo_res);

          window.ma.callNativeAPI('startPay', {
              prepayId: backend_res.prepay_id,
              orderInfo: backend_res.order_info,
              sign: backend_res.sign_app,
              signType: 'SHA256',
              disableNewCheckout: 'false',
              tradeType: "MINIAPP"
          }, res => {
            console.log("startPay api res: ", res);
            if (res.resultCode == 1) {
              EventBus.$emit("clear-cache");
              console.log("startpaysucess")
              if(this.isMM) {
                this.$swal(
                "အောင်မြင်ပါသည်",
                "ဝယ်ယူပြီးပါပြီ။ အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါ။",
                "success"
              );
              } else {
                this.$swal(
                "Success",
                "Purchased. Please contact 09456880335 if you have any problems.",
                "success"
              );
              }
              this.$router.push({ name: "Tarot Shuffle" });
            } else {
                if(this.isMM) {
                  this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                } else {
                  this.$swal("Fail", "Please try again", "error");
                }
                this.disabled = false;
            }
          });
          // this.kbzpay.startPay(
          //   backend_res.prepay_id,
          //   backend_res.order_info,
          //   backend_res.sign_app,
          //   ({ resultCode }) => {
          //     if (resultCode == 1) {
          //       this.$swal(
          //         "အောင်မြင်ပါသည်",
          //         "ဝယ်ယူပြီးပါပြီ။ တစ်နေ့တာ တားရော့ကံကြမ္မာ ရယူနိုင်ပါပီ။ အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါရန်။",
          //         "success"
          //       );
          //       this.$router.push({ name: "Tarot Shuffle" });
          //     } else if (resultCode == 2) {
          //       this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
          //       this.disabled = false;
          //     }
          //   },
          //   () => {
          //     this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
          //     this.disabled = false;
          //   }
          // );
        } catch (err) {
          console.log(err);
          if(this.isMM) {
            this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
          } else {
            this.$swal("Fail", "Please try again", "error");
          }
          this.disabled = false;
        }
      } else {
        if(this.isMM) {
          this.$swal(
          "Oops",
          "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
          "error"
        );
        } else {
          this.$swal(
          "Oops",
          "You need to fill all fields.",
          "error"
        );
        }
      }
    },

    async makePayment() {
      if (checkFormModel(this.tarot_shuffle)) {
        this.disabled = true;

        let form_data = getFormData(this.tarot_shuffle);
        form_data.append("payment_method", "mpitesan");
        form_data.append("srcFrom", "MPitesan");
        let cdata = {
          category_id: this.tarot_shuffle.category_id,
          category_name: this.tarot_shuffle.category_name,
          payment_name: this.actionBtnMethod,
          phone_number: this.tarot_shuffle.phone,
        };

        try {
          let res = await await makePayment([
            "1875/tarot-shuffle/horoscope",
            form_data,
            cdata,
            this.$store.state.backendUrl,
          ]);

          if (res.status == 200) {
            this.$store.state.order_id = res.order_id;
            this.$store.state.amount = res.amount;
            if (!res.payment_status) {
              this.$router.push({
                name: "paymentPortal",
              });
            } else {
              this.$router.push({
                name: "PaymentSuccess",
                params: { payment: "mpitesan" },
                query: { category_id: this.tarot_shuffle.category_id },
              });
            }
          } else {
            throw new Error("Fail");
          }
        } catch (err) {
          console.log(err);
          this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
          this.disabled = false;
        }
      } else {
        this.$swal(
          "Oops",
          "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
          "error"
        );
      }
    },

    async onePayProvider() {
      if (checkFormModel(this.tarot_shuffle)) {
        this.disabled = true;
        let form_data = getFormData(this.tarot_shuffle);
        form_data.append("srcFrom", "OnePay");
        form_data.append("payment_method", "onepay");
        let cdata = {
          category_id: this.tarot_shuffle.category_id,
          category_name: this.tarot_shuffle.category_name,
          phone_number: this.onepayPhoneNumber || this.tarot_shuffle.phone,
        };

        try {
          let res = await onePay([
            "1875/tarot-shuffle/horoscope",
            form_data,
            cdata,
            this.$store.state.backendUrl,
          ]);
          if (res.status == 200) {
            this.$store.state.order_id = res.order_id;
            this.$store.state.amount = res.amount;
          } else {
            throw new Error("Fail");
          }
        } catch (err) {
          console.log(err);
          this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
        }
      } else {
        this.$swal(
          "Oops",
          "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
          "error"
        );
      }
    },

    async citizenPayProvider() {
      if (checkFormModel(this.tarot_shuffle)) {
        this.disabled = true;
        let form_data = getFormData(this.tarot_shuffle);
        form_data.append("srcFrom", "CitizenPay");
        form_data.append("payment_method", "citizenpay");

        try {
          let cdata = {
            category_id: this.tarot_shuffle.category_id,
            category_name: this.tarot_shuffle.category_name,
          };
          let res = await citizenPay([
            "1875/tarot-shuffle/horoscope",
            form_data,
            cdata,
            this.$store.state.backendUrl,
          ]);
          if (res.status == 200) {
            window.location.href = res.paymentGatway;
          } else {
            this.$swal("Oops", "Something went wrong!", "error");
          }
        } catch (err) {
          console.log(err);
          this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
        }
      } else {
        this.$swal(
          "Oops",
          "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
          "error"
        );
      }
    },

    async bpPay() {
      if (checkFormModel(this.tarot_shuffle)) {
        this.disabled = true;
        let form_data = getFormData(this.tarot_shuffle);
        form_data.append("srcFrom", "PWA BpPay");
        form_data.append("payment_method", "bppay");

        let cdata = {
          category_id: this.tarot_shuffle.category_id,
          category_name: this.tarot_shuffle.category_name,
          phone_number: this.tarot_shuffle.phone,
          payment_name: this.actionBtnMethod,
        };

        try {
          let res = await makePayment([
            "1875/tarot-shuffle/horoscope",
            form_data,
            cdata,
            this.$store.state.backendUrl,
          ]);

          if (res.status == 200) {
            this.$store.state.order_id = res.order_id;
            this.$store.state.amount = res.amount;
            if (!res.payment_status) {
              this.$router.push({
                name: "paymentPortalV3",
              });
            } else {
              this.$router.push({
                name: "PaymentSuccess",
                params: { payment: "bppay" },
                query: { category_id: this.tarot_shuffle.category_id },
              });
            }
          } else {
            throw new Error("Fail");
          }
        } catch (err) {
          console.log(err);
          this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
        }
      } else {
        this.$swal(
          "Oops",
          "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
          "error"
        );
      }
    },

    validateForm() {
      this.errors = {};
      if(this.tarot_shuffle.name === '' || !this.tarot_shuffle.name) this.errors.name = 'အမည်ထည့်ရန်လိုအပ်ပါသည်။'
      this.errors.phone = validatePhoneNumber(this.tarot_shuffle.phone)
    },
  },
};
</script>

<style scoped>
</style>
