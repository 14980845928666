<template>
  <component :is="layout">
    <keep-alive :include="cachedComponents" :key="keepAliveKey">
      <router-view />
    </keep-alive>
  </component>
</template>

<!-- <script src="../public/js/vconsole.min.js"></script> -->
<script>
const DefaultLayout = () =>
  import(/* webpackChunkName: "vsf-layout-default" */ "./layouts/Default");
  import {EventBus} from "../public/js/eventBus";
export default {
  name: `App`,
  data() {
    return {
      cachedComponents: ['TarotShuffleForm', 'Clairvoyance', 'NewYearBaydin', 'CompatabilityLoveForm', 'Special', 'Tarot'],
      keepAliveKey: 0,
    }
  },
  created() {
    EventBus.$on('clear-cache', this.handleClearCache);
  },
  beforeDestroy() {
    EventBus.$off('clear-cache', this.handleClearCache);
  },
  methods: {
    handleClearCache() {
      this.cachedComponents = [];
      setTimeout(() => {
        this.cachedComponents = ['TarotShuffleForm', 'Clairvoyance', 'NewYearBaydin', 'CompatabilityLoveForm', 'Special', 'Tarot'];
      }, 3000);
    },
  },
  components: {
    DefaultLayout,
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || "default"}-layout`;
    },
    // kbzToken() {
    //   return this.$store.state.kbzToken;
    // }
  },
  async beforeCreate() {
    await this.$store.dispatch('fetchKBZToken');
    // await window.ma.callNativeAPI('setNavigationBarTitle', {title: '1875 Bay Din'}, result => {
    //   console.log("new title: ", result);
    // })
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html {
  line-height: 1.6;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

p {
  &:not(:first-child) {
    margin-top: 1.25em;
  }
}

.mm-font {
  font-family: Unicode;
}

input::placeholder {
  color: grey !important;
}

.t_c_agreement {
  color: slategrey;
  font-size: 12px; 
  font-weight: normal;
}

.t_c_agreement span {
  text-decoration: underline;
  color: #333;
  cursor: pointer;
}

.tnc {
  border: 2px solid black;
  border-radius: 10px;
  height: calc(100vh - 150px); 
  margin: -15px;
  overflow: hidden; 
  padding: 10px;
}

.scroll-text {
  max-height: calc(100% - 20px); 
  overflow-y: auto;
}

</style>
