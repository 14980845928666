import * as types from "./mutation-types";
import { HTTP } from "@core/lib/http-common";

const actions = {
  async queryCustInfo({ commit }, token) {
    console.log("queryCustInfo", token);

    commit(types.CUST_REQUEST);

    return HTTP.post("user/user_info", {
      access_token: token,
    })
      .then((response) => {
        // localStorage.setItem('access_token', response.data.access_token)
        commit(types.CUST_SUCCESS, response.data);
        console.log(" questCustInfo response.data", response.data);
      })
      .catch((err) => {
        // localStorage.removeItem('access_token') // if the request fails, remove any possible user token if possible
        commit(types.CUST_ERROR, err.response.data);
        console.log(" questCustInfo error ", err);
      });
  },
  async login({ commit, dispatch, getters }, token) {
     console.log(" login ", token);
     console.log("reponse getOpenID", getters.getOpenID);

     commit(types.AUTH_REQUEST);

     await dispatch("queryCustInfo", token);

     console.log("queryCustInfo dispatch ", token);
     console.log("reponse queryCustInfo", getters.getResponse);
     console.log("reponse getOpenID", getters.getOpenID);

    return HTTP.post("user/authenticate", {
      email: getters.getOpenID,
      password: "12345678",
    })
      .then((response) => {
        localStorage.setItem("access_token", response.data.access_token);
        commit(types.AUTH_SUCCESS, response.data);
      })
      .catch((err) => {
        commit(types.AUTH_ERROR, err.response.data);
        localStorage.removeItem("access_token"); // if the request fails, remove any possible user token if possible
      });
  },
};

export default actions;
