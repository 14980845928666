export const validatePhoneNumber = (phone) => {
    if (!phone) {
      return "ဖုန်းနံပါတ်ထည့်ရန်လိုအပ်ပါသည်။";
    }
  
    // Ensure phone is a string and remove non-numeric characters
    const cleanedPhone = phone.toString().replace(/\D/g, '');
  
    if (cleanedPhone.startsWith('09')) {
      if (cleanedPhone.length < 9 || cleanedPhone.length > 11) {
        return "မှန်ကန်သောဖုန်းနံပါတ်ထည့်ပါ။";
      }
    } else if (cleanedPhone.startsWith('959')) {
      if (cleanedPhone.length < 10 || cleanedPhone.length > 12) {
        return "မှန်ကန်သောဖုန်းနံပါတ်ထည့်ပါ။";
      }
    } else {
      if (cleanedPhone.length < 7 || cleanedPhone.length > 9) {
        return "မှန်ကန်သောဖုန်းနံပါတ်ထည့်ပါ။";
      }
    }
  
    return ""; // No error
  };  