<template>
  <section class="mt-5">
    <div class="p-2">
      <div class="row m-0 p-0">
        <div class="container">
          <div
              v-if="is_oneyear"
              class="card p-2"
              style="
                border-color: #eb6224 !important;
                border-radius: 20px !important;
              "
          >
            <div class="order__card col-md-12 col-12 col-lg-12 col-xl-12 subscribe">
              <div class="baydin_title d-flex my-2">
                Service Name : <span>{{this.service_name}}</span>
              </div>

              <div class="baydin_date d-flex mb-2">
                Price : {{this.price}}MMK
              </div>

              <div class="baydin_day d-flex">Date : {{moment(this.date).format("YYYY-MM-DD HH:mm:ss")}}</div>
              <hr class="my-3" />
              <div v-if="oneyear_content">
                <div class="col-md-12">
                  <div class="">
                    {{oneyear_content}}
                  </div>
                </div>
              </div>
              <div v-if="!is_answer" class="mb-3">
                <div>
                  <div class="">
                    ၃ ရက်အတွင်းဟောစာတမ်းကိုပြန်လည်ဖြေကြားပေးပါမည်
                  </div>
                </div>
                <div
                    class="btn"
                >အဖြေစောင့်ဆိုင်းနေပါသည်</div>
              </div>
              <div v-if="is_answer">
                <div
                    @click.prevent="downloadItem()"
                    class="btn"
                >အဖြေရယူမည်</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import {ONEYEAR, Horo} from "@core/lib/http-common";
// import axios from "axios";
// import { HTTP } from "@core/lib/http-common";

export default {
  name: `Details`,
  data() {
    return {
      is_answer: false,
      is_oneyear: true,
      show_question: true,
      question: null,
      order_id: null,
      service_name: null,
      price: null,
      date: null,
      category_id: null,
      userId: null,
      oneyear_content: null,
      item: "",
      status: "",
      type: "",
      file: "",
    }
  },
  computed: {
    ...mapGetters({
      getOpenID: "userStore/getOpenID"
    })
  },
  async created() {
    this.order_id = this.$route.params.orderId;
    this.service_name = this.$route.params.serviceName;
    this.price = this.$route.params.price;
    this.date = this.$route.params.date;
    this.category_id = this.$route.params.categoryId;
    this.userId = this.$route.params.userId;

    if(this.category_id == 11001101) {
      this.is_oneyear = false;
      let data = {
        user_id: this.userId,
        paymentName: "KBZPAY",
        auth1: "1875BaydinUser",
        auth2: "1875BaydinUserP@ssword",
      };
      console.log("data for one year: ",data);
      ONEYEAR.post("orders", data)
          .then(({ data }) => {
            let oneyear_data = data.items;
            if(oneyear_data && oneyear_data.length > 0) {
              let filtered_data = oneyear_data.filter((item) => item.order_id == this.order_id);
              this.$router.push({
                name: 'orderDetail',
                params: {detail: filtered_data[0]}
              })
            }
            console.log("Order api order values:", oneyear_data);
          })
          .catch(() => {
            console.log("Unauthorize!");
          });
    }

    await Horo.post("1875/horoscope/getFile", {
      order_id: this.order_id,
    }).then((response) => {
      this.item = response.data;
      this.status = response.data.status;
      this.type = response.data.file.split(".").pop();
      this.file = response.data.file;
      if(response.data.name){
        this.is_answer = true;
      }
    });
  },
  methods: {
    downloadItem() {
      console.log("click downloadItem ", this.file);
      window.ma.downloadFile({
        url: this.file,
        success: function (res) {
          const filePath = res.tempFilePath;
          window.ma.openDocument({
            filePath: filePath,
            success: function (res) {
              console.log("open document: ", res);
              console.log("The document is opened successfully!");
            }
          })
          console.log("file download: ", res);
        }
      })
      // window.ma.openDocument({
      //   filePath: this.file,
      //   success: function (res) {
      //     console.log("open success: ", res);
      //   }
      // })
    },
  }
}
</script>

<style scoped>
.btn {
  width: auto;
  min-width: 80px;
  height: 20px;
  border-radius: 5.5cm;
  display: inline-flex;
  border: none;
  font-weight: 200;
  font-size: 11px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 5px;
  background: #eb6224;
  padding: 15px 20px; /* Add padding for better appearance */
}
</style>