<template>
    <section class="mt-5">
      <div class="p-2">
        <div class="row m-0 p-0">
          <div class="container">
            <div
              class="card p-2"
              style="
                border-color: #eb6224 !important;
                border-radius: 20px !important;
              "
            >
              <div class="order__card col-md-12 col-12 col-lg-12 col-xl-12 subscribe">
                <div class="baydin_title d-flex my-2">
                  Service Name : <span>{{this.service_name}}</span>
                </div>

                <div class="baydin_date d-flex mb-2">
                  Price : {{this.price}}MMK
                </div>

                <div class="baydin_day d-flex">Date : {{moment(this.date).format("YYYY-MM-DD HH:mm:ss")}}</div>
                <div v-if="show_question">
                  <hr class="my-3">
                  <div class="d-flex mb-2 font-weight-bold">
                    Question
                  </div>
                  <div>
                    {{question}}
                  </div>
                </div>
<!--                <button class="baydin-question d-flex align-items-start mb-2">-->
<!--                    Questions-->
<!--                </button>-->
<!--                <div class="btn">အဖြေကြည့်ရန်</div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import { HTTP } from "@core/lib/http-common";

export default {
    name: `Details`,
    data() {
        return {
          show_question: true,
          question: null,
          order_id: null,
          service_name: null,
          price: null,
          date: null,
          category_id: null,
          tarots: [],
          clairvoyances: [],
          starBaydins: [],
          callService: [],
          combinations: [],
        }
    },
    computed: {
      ...mapGetters({
        getOpenID: "userStore/getOpenID"
      })
    },
    async created() {
        this.order_id = this.$route.params.orderId;
        this.service_name = this.$route.params.serviceName;
        this.price = this.$route.params.price;
        this.date = this.$route.params.date;
        this.category_id = this.$route.params.categoryId;

        const openID = this.getOpenID;
        // const openID = 'b15bf6eb7ba121785c2576bf15ad17bcd149927fac2716412200191f94879549';
        const response = await HTTP.get(`questions/${openID}`);
        this.tarots = response.data.data.tarots;
        this.clairvoyances = response.data.data.clairvoyances;
        this.starBaydins = response.data.data.starBaydins;
        this.callService = response.data.data.callService;

        this.combineArray();

        const order = this.combinations.filter(record => record.order_id === this.order_id);

        if(order.length > 0) {
          this.show_question = true;
          this.question = order[0].about;
        }
        console.log("order question: ", order);
    },
    methods: {
      combineArray() {
        this.combinations = [
            ...this.tarots,
            ...this.clairvoyances,
            ...this.starBaydins,
            ...this.callService
        ]
      }
    }
}

</script>

<style scoped>
  h4 {
    font-size: 16px;
    line-height: 20px;
    color: #eb6224;
    font-weight: 300;
}

button {
    border-radius: 5px !important;
}

.btn {
  width: 80px;
  height: 20px;
  border-radius: 5.5cm;
  display: inline-flex;
  border: none;
  font-weight: 200;
  font-size: 11px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 5px;
  background: #eb6224;
  margin-right: 15px;
}

@media screen and (max-width: 480px) {
  .baydin_title, .baydin_date, .baydin_day {
    font-size: 14px;
  }
}
</style>