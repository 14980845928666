<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <article>
            <div class="entry-content">
              <div class="woocommerce">
                <div class="woocommerce-MyAccount-content">
                  <form id="child-form" role="form" style="display: block">
                    <p class="mm-font __mm" align="center">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <br />
                    <br />
                    <label class="form-group">
                      <span class="mm-font __mm __mm">အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="tarot.name"
                        name="name"
                      />
                      <span class="error__message" v-if="!tarot.name && errors.name">
                        <i class="pi pi-exclamation-circle mr-1"></i>
                        {{ errors.name }}
                      </span>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးသက္ကရာဇ်</span>

                      <md-datepicker
                        :md-disabled-dates="disablePastDates"
                        v-model="tarot.birthday"
                        name="birthday"
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        required
                        ref="datepicker"
                      >
                      <label class="date__picker" v-if="!tarot.birthday">YYYY-MM-DD</label>
                      </md-datepicker>
                      <div class="error__message" v-if="!tarot.birthday && errors.birthday">
                        <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                        {{ errors.birthday }}
                      </div>
                    </label>
                    <label class="form-group">
                      <span for class="mm-font __mm">နေ့နံ</span>
                      <div class="dropdown__container">
                        <select
                        class="mm-font __mm"
                        v-model="tarot.birth_day"
                        name="birth_day"
                        required
                      >
                        <option value="" class="__mm" selected>
                          နေ့နံရွေးချယ်ပါ
                        </option>
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>
                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                      <i class="pi pi-chevron-down dropdown__icon"></i>
                      </div>
                      <span class="error__message" v-if="!tarot.birth_day && errors.birth_day">
                          <i class="pi pi-exclamation-circle mr-1"></i>
                          {{ errors.birth_day }}
                      </span>
                    </label>
                    <input
                      type="radio"
                      value="Yes"
                      required
                      name="martial_status"
                      v-model="tarot.martial_status"
                    />
                    <span for class="mm-font __mm" style="margin-right: 20px"
                      >အိမ်ထောင်ရှိ</span
                    >
                    <input
                      type="radio"
                      value="No"
                      required
                      name="martial_status"
                      v-model="tarot.martial_status"
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်မရှိ</span>
                    <div class="error__message" v-if="!tarot.martial_status && errors.martial_status">
                        <i class="pi pi-exclamation-circle mr-1"></i>
                        {{ errors.martial_status }}
                    </div>
                    <br /><br />
                    <label class="form-group">
                      <span for class="mm-font __mm">အလုပ်အကိုင်</span>
                      <div
                        style="
                          display: grid;
                          grid-template-rows: 1fr 1fr;
                          gap: 5px;
                        "
                      >
                        <div class="dropdown__container">
                          <select
                          name="job"
                          class="mm-font __mm"
                          v-model="tarot.job"
                          required
                        >
                          <option value="" selected hidden>
                            အလုပ်အကိုင် အမျိုးအစား
                          </option>
                          <option value="အစိုးရဝန်ထမ်း">အစိုးရဝန်ထမ်း</option>
                          <option value="ကုမ္ပဏီဝန်ထမ်း">ကုမ္ပဏီဝန်ထမ်း</option>
                          <option value="ကိုယ်ပိုင်စီးပွားရေး">
                            ကိုယ်ပိုင်စီးပွားရေး
                          </option>
                          <option value="ကျောင်းသား/သူ">ကျောင်းသား/သူ</option>
                          <option value="အခြား">အခြား</option>
                        </select>
                        <i class="pi pi-chevron-down dropdown__icon"></i>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          name="job_detail"
                          v-model="tarot.job_detail"
                          placeholder="အလုပ်ကိုင်အသေးစိတ် "
                        />
                        <div class="error__message" v-if="(!tarot.job || !tarot.job_detail) && errors.job">
                          <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                          {{ errors.job }}
                        </div>
                      </div>
                    </label>
                    <input
                      type="radio"
                      value="Male"
                      v-model="tarot.gender"
                      name="gender"
                      required
                    />
                    <span for class="mm-font __mm" style="margin-right: 20px"
                      >ကျား</span
                    >
                    <input
                      type="radio"
                      value="Female"
                      v-model="tarot.gender"
                      name="gender"
                      required
                    />
                    <span for class="mm-font __mm">မ</span>
                    <div class="error__message" v-if="!tarot.gender && errors.gender">
                      <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                      {{ errors.gender }}
                    </div>
                    <br /><br />
                    <label class="form-group">
                      <span for="" class="mm-font">
                        Viber အသုံးပြုသော ဖုန်းနံပါတ်အားထည့်ပါ</span
                      >
                      <input
                        type="number"
                        class="form-control"
                        v-model="tarot.viber_no"
                        name="viber_no"
                        placeholder="09xxxxxxxxx"
                        @input="validateForm"
                        required
                      />
                      <div class="error__message" v-if="errors.viber_no">
                        <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                        {{ errors.viber_no }}
                      </div>
                    </label>
                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                        <div class="dropdown__container">
                          <select
                          v-model="tarot.address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <i class="pi pi-chevron-down dropdown__icon"></i>
                        </div>
                        <div class="dropdown__container">
                          <select
                          v-model="tarot.address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                        <i class="pi pi-chevron-down dropdown__icon"></i>
                        </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/လမ်း အသေးစိတ်"
                        v-model="tarot.address"
                        required
                      />
                      <div class="error__message" v-if="(!tarot.address || !this.tarot.address_division || !this.tarot.address_township) && errors.address">
                        <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                        {{ errors.address }}
                    </div>
                    </label>
                    <label class="form-group">
                      <span for class="mm-font__mm"
                        >မေးမြန်းလိုသည့်မေးခွန်းအရေအတွက်</span
                      >
                      <div>
                        <input
                          type="radio"
                          value="2"
                          v-model="tarot.q_quantity"
                          name="q_quantity"
                        />
                        <span style="margin-right: 20px">၂ခု</span>
                        <input
                          type="radio"
                          value="4"
                          v-model="tarot.q_quantity"
                          name="q_quantity"
                        />
                        <span>၄ခု</span>
                        <div class="error__message" v-if="!tarot.q_quantity && errors.q_quantity">
                          <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                          {{ errors.q_quantity }}
                        </div>
                      </div>
                    </label>
                    <p class="mm-font">မိမိသိလိုသော ကြောင်းအရာ</p>
                    <label class="form-group">
                      <textarea
                        v-model="tarot.about"
                        name="about"
                        class="mm-font test"
                        rows="10"
                        cols="35"
                        style="color: black"
                        required
                      ></textarea>
                      <div class="error__message" v-if="!tarot.about && errors.about">
                        <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                        {{ errors.about }}
                      </div>
                    </label>

                    <p class="t_c_agreement">By continuing, you agree to <span @click.prevent="redirectTNC()">Terms and Conditions.</span></p>

                    <p>ဉာဏ်ပူဇော်ခ {{ this.price }} ကျပ် ကျသင့်ပါမည်။</p>
                    <p>
                      <input
                        @click.prevent="handleSubmit()"
                        type="submit"
                        :disabled="disabled"
                        class="woocommerce-Button button mm-font __mm"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                  <br />
                  <div>
                    <div class="col-md-12">
                      <p class="mm-font __mm cat">
                        ဟောစာတမ်း အသံဖိုင်အား ၃ ရက်အတွင်း
                        အထက်ပါဖြည့်ထားသောဖုန်းနံပါတ်အားပေးပို့ပေးပါမည်။
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
import {checkFormModel, getFormData} from "@core/lib/form-data";
import {Horo, HTTP} from "../../core/lib/http-common";
import {mapGetters} from "vuex";
import {citizenPay, makePayment, onePay} from "../js/payment";
import {getDivisions, getTownships} from "../js/addressInfo";
import {EventBus} from "../../public/js/eventBus";
import { validatePhoneNumber } from "../utils/validationPhone";

export default {
  name: "Tarot",
  data() {
    return {
      datepicker: null,
      divisions: [],
      townships: [],
      actionBtnMethod: "",
      disabled: false,
      onepayPhoneNumber: "",
      tarot: {
        name: "",
        birthday: "",
        birth_day: "",
        martial_status: "",
        job: "",
        job_detail: "",
        gender: "",
        viber_no: "",
        address: "",
        address_division: "",
        address_township: "",
        about: "",
        q_quantity: "",
        price: "",
        user_id: "0",
        order_id: "0",
      },
      errors: {},
      isMM: false,
    };
  },
  async created() {
    this.divisions = await getDivisions();
    this.actionBtnMethod = this.$store.state.paymentName;
  },
  mounted() {
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";

    if (this.$refs.datepicker) {
      const input = this.$refs.datepicker.$el.querySelector("input");
      if (input) {
        input.setAttribute("readonly", "true");
      }
  }

    this.getLocale();
  },
  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),

    price: {
      get() {
        return this.tarot.q_quantity == "2"
          ? 5000
          : this.tarot.q_quantity == "4"
          ? 7000
          : 5000;
      },
    },

    disablePastDates() {
      const today = new Date();
      return (date) => new Date(date) >= today;
    }
  },
  methods: {
    getLocale() {
        window.ma.callNativeAPI("getLocale", {}, res => {
        this.isMM = res.language === "my-MM";
      })
    },

    async handleDivisionChange(e) {
      let nrc_id = e.target.options[e.target.selectedIndex].dataset.nrc;
      this.townships = await getTownships(nrc_id);
    },

    redirectTNC() {
        this.$router.push('/category/terms_and_conditions')
    },

    handleSubmit() {
      this.validateForm();
      this.paymentProvider();
    },
    handleQuantityChange() {},
    paymentProvider() {
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        case "citizenpay":
          this.citizenPayProvider();
          break;
        case "bppay":
          this.bpPay();
          break;
        default:
          this.precreate();
          break;
      }
    },

    precreate() {
      this.tarot.price = this.price; // this must be set at very first
      if (checkFormModel(this.tarot)) {
        this.disabled = true; // disable button after form validation success
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 3476,
          amount: this.tarot.price,
          quantity: this.tarot.q_quantity,
          category_name: "tarot",
          name: this.tarot.name,
        })
          .then((response) => {
            const res = response.data;
            this.tarot.user_id = res.user_id;
            this.tarot.order_id = res.order_id;

            let form_data = getFormData(this.tarot);
            form_data.set("price", res.amount);
            form_data.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            form_data.append("payment_method", "KBZPay");
            form_data.append("srcFrom", "KBZPay");
            Horo.post("1875/tarot/horoscope", form_data).then((response) => {
              this.res_status = response.data.status;
              
              // new kbzpay mini_app api
              window.ma.callNativeAPI('startPay', {
                prepayId: res.prepay_id,
                orderInfo: res.order_info,
                sign: res.sign_app,
                signType: 'SHA256',
                disableNewCheckout: 'false',
                tradeType: "MINIAPP"
                }, res => {
                console.log("startPay api res: ", res);
                if (res.resultCode == 1) {
                  EventBus.$emit("clear-cache");
                  console.log("startpaysucess")
                  if (!this.isMM) {
                    this.$swal(
                      "Success",
                      "Purchased. Please contact 09456880335 if you have any problems.",
                      "success"
                    );
                    this.$router.push({ name: "home" });
                  }
                  if (this.isMM) {
                    this.$swal(
                      "အောင်မြင်ပါသည်",
                      "ဝယ်ယူပြီးပါပြီ။ အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါ။",
                      "success"
                    );
                    this.$router.push({ name: "home" });
                  }
                }else {
                  if (!this.isMM) {
                    this.$swal("Fail", "Please try again", "error");
                    this.disabled = false;
                  }
                  if (this.isMM) {
                    this.$swal(
                      "မအောင်မြင်ပါ",
                      "ထပ်မံကြိုးစားပေးပါ",
                      "error"
                    );
                    this.disabled = false;
                  }
                }
              });
              // this.kbzpay.startPay(
              //   res.prepay_id,
              //   res.order_info,
              //   res.sign_app,
              //   ({ resultCode, lang }) => {
              //     if (resultCode == 1) {
              //       if (lang == "en") {
              //         this.$swal("Success", "Your Purchase Success", "success");
              //         this.$router.push({ name: "home" });
              //       }
              //       if (lang == "my") {
              //         this.$swal("အောင်မြင်ပါသည်", "ဝယ်ယူပြီးပါပြီ", "success");
              //         this.$router.push({ name: "home" });
              //       }
              //     }
              //     if (resultCode == 2) {
              //       if (lang == "en") {
              //         this.$swal("Failed", "Your Purchase Failed", "error");
              //         this.disabled = false;
              //       }
              //       if (lang == "my") {
              //         this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
              //         this.disabled = false;
              //       }
              //     }
              //   },
              //   () => {
              //     this.$swal("Failed", "Your Purchase Failed", "error");
              //     this.disabled = false;
              //   }
              // );
            });
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        if(this.isMM) {
          this.$swal(
          "Oops",
          "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
          "error"
        );
        } else {
          this.$swal(
          "Oops",
          "You need to fill all fields.",
          "error"
        );
        }
      }
    },

    async bpPay() {
      this.tarot.price = this.price;
      if (checkFormModel(this.tarot)) {
        this.disabled = true;

        let form_data = getFormData(this.tarot);
        form_data.append("user_phone", this.tarot.viber_no);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        form_data.append("payment_method", payment_method);
        form_data.append("srcFrom", "PWA BPPay");

        let cdata = {
          category_id: 3476,
          amount: this.tarot.price,
          category_name: "tarot",
          quantity: this.tarot.q_quantity,
          phone_number: this.tarot.viber_no,
          payment_name: this.actionBtnMethod,
        };

        await makePayment([
          "1875/tarot/horoscope",
          form_data,
          cdata,
          this.$store.state.backendUrl,
          this.tarot.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "bppay" },
                });
              }
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      this.tarot.price = this.price; // this must be set at very first
      if (checkFormModel(this.tarot)) {
        this.disabled = true;

        let form_data = getFormData(this.tarot);
        form_data.append("user_phone", this.tarot.viber_no);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        form_data.append("payment_method", payment_method);
        form_data.append("srcFrom", "MPitesan");
        let cdata = {
          category_id: 3476,
          quantity: this.tarot.q_quantity,
          amount: this.tarot.price,
          category_name: "tarot",
          // promocode: this.promocode,
          phone_number: this.tarot.viber_no,
          payment_name: this.actionBtnMethod,
        };

        await makePayment([
          "1875/tarot/horoscope",
          form_data,
          cdata,
          this.$store.state.backendUrl,
          this.tarot.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async onePayProvider() {
      this.tarot.price = this.price; // this must be set at very first
      if (checkFormModel(this.tarot)) {
        this.tarot.user_id = this.onepayPhoneNumber;
        this.disabled = true;

        let form_data = getFormData(this.tarot);
        form_data.append("user_phone", this.tarot.viber_no);
        form_data.append("srcFrom", "OnePay");
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        form_data.append("payment_method", payment_method);
        let cdata = {
          category_id: 3476,
          quantity: this.tarot.q_quantity,
          amount: this.tarot.price,
          category_name: "tarot",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/tarot/horoscope",
          form_data,
          cdata,
          this.$store.state.backendUrl,
          this.tarot.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async citizenPayProvider() {
      this.tarot.price = this.price;
      if (checkFormModel(this.tarot)) {
        this.disabled = true;
        let form_data = getFormData(this.tarot);
        form_data.append("user_phone", this.tarot.viber_no);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        form_data.append("payment_method", payment_method);
        form_data.append("srcFrom", "Citizen Pay");

        let cdata = {
          category_id: 3476,
          quantity: this.tarot.q_quantity,
          amount: this.tarot.price,
          category_name: "tarot",
        };
        await citizenPay([
          "1875/tarot/horoscope",
          form_data,
          cdata,
          this.$store.state.backendUrl,
          this.tarot.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    validateForm() {
      this.errors = {};
      
      if(this.tarot.name === '' || !this.tarot.name) this.errors.name = 'အမည်ထည့်ရန်လိုအပ်ပါသည်။'
      if(this.tarot.birthday === '' || !this.tarot.birthday) this.errors.birthday = 'မွေးသက္ကရာဇ်ထည့်ရန်လိုအပ်ပါသည်။'
      if(this.tarot.birth_day === '' || !this.tarot.birth_day) this.errors.birth_day = 'နေ့နံရွေးချယ်ရန်လိုအပ်ပါသည်။'
      if(this.tarot.martial_status === '' || !this.tarot.martial_status) this.errors.martial_status = "အိမ်ထောင်ရှိ/မရှိ ရွေးချယ်ရန်လိုအပ်ပါသည်။"
      if((this.tarot.job === '' || !this.tarot.job) || (this.tarot.job_detail === '' || !this.tarot.job_detail)) this.errors.job = "အလုပ်အကိုင်အမျိုးအစားရွေးချယ်ရန်လိုအပ်ပါသည်။"
      if(this.tarot.gender === '' || !this.tarot.gender) this.errors.gender = "ကျား/မ ရွေးချယ်ရန်လိုအပ်ပါသည်။"
      if((this.tarot.address_division === '' || !this.tarot.address_division) || (this.tarot.address_township === '' || !this.tarot.address_township) || (this.tarot.address === '' || !this.tarot.address)) this.errors.address = "နေရပ်လိပ်စာထည့်ရန်လိုအပ်ပါသည်။"
      if(!this.tarot.q_quantity || this.tarot.q_quantity === '') this.errors.q_quantity = "မေးခွန်းအရေအတွက်ရွေးချယ်ရန်လိုအပ်ပါသည်။"
      if(!this.tarot.about || this.tarot.about === '') this.errors.about = "သိလိုသောအကြောင်းအရာထည့်ရန်လိုအပ်ပါသည်။"
      this.errors.viber_no = validatePhoneNumber(this.tarot.viber_no)
    },
  },
};
</script>

<style >

input {
  background-color: red;
}

input[type="radio"] {
  display: inline !important;
  margin-right: 5px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-container {
  display: flex;
  border: 1px solid #000;
  background-color: #f2eff7;
  color: #3e1e68;
  border-radius: 25px;
  align-items: center;
  padding: 0px 0px 0px 10px;
  margin: 0px !important;
}

.date__picker {
  margin-top: -8px !important;
  margin-left: 12px !important;
}

.md-field.md-theme-default:after {
  background-color: rgba(0, 0, 0, 0) !important;
}

.md-button.md-theme-default {
  margin: 0px 16px 5px 0px;
}

.md-field.input-container.md-theme-default.md-clearable.md-datepicker {
  padding-top: 0px;
}
</style>