import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import userStore from "@horo/core/modules/user/store";
import categoryStore from "@horo/core/modules/category/store";
import envConfig from "../config/envConfig";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        paymentName: " ",
        backendUrl: envConfig.appBackendUrl,
        backendBPpayUrl: envConfig.appBackendBPpayUrl,
        backendMpiteSanUrl: envConfig.appBackendMpiteSanUrl,
        backendOnepayUrl: envConfig.appBackendOnePayUrl,
        backendCitizenPayUrl: envConfig.appBackendCitizenPayUrl,
        paymentPortalUrl: envConfig.paymentPortalURL,
        paymentPortalV3Url: envConfig.paymentPortalV3URL,
        citizenpayCheckPaymentStatusURL: envConfig.citizenpayCheckPaymentStatus,
        kbzMerchantAppId: envConfig.kbzMerchantAppId,
        
        merchant_id: envConfig.bpPayMpitesanMerchantId,
        service_name: envConfig.bpPayMpitesanServiceName,
        email: envConfig.bpPayMpitesanEmail,
        password: envConfig.bpPayMpitesanPassword,
        merchant_key: envConfig.bpPayMpitesanKey,
        amount: 0,
        order_id: "",
        onepayPhoneNumber: "",

        kbzToken: null,
    },
    mutations: {
        getPaymentName(state, paymentName) {
            state.paymentName = paymentName;
        },
        SET_KBZ_TOKEN(state, token) { 
            state.kbzToken = token;
        }
    },
    actions: {
        async fetchKBZToken({ commit, state }) {
        try {
            const result = await new Promise((resolve, reject) => {
                console.log("app id from state: ",state.kbzMerchantAppId);
                window.ma.callNativeAPI('gethwssostring', { merchantAppId: state.kbzMerchantAppId }, (res) => {
                    if (res && res.xm_string_callback_key) {
                        resolve(res.xm_string_callback_key);
                    } else {
                        reject(new Error('Unexpected response'));
                    }
                });
            });
    
            commit('SET_KBZ_TOKEN', result);
        } catch (error) {
            console.error('Failed to fetch KBZ token:', error);
        }
        },
    },
    getters: {
        getRoutePath() {
            return router.currentRoute.path;
        },
        getRouteParams() {
            return router.currentRoute.params;
        },
    },
    modules: {
        userStore,
        categoryStore,
    },
});
