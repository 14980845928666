<!-- <template>
  <section class="ds s-pt-30 s-pb-20 s-pt-xl-60 s-pb-xl-40 c-gutter-60 c-mb-30">
    <div class="container">
      <div class="row" id="box">
        <div class="col-xl-12 col-lg-12 col-12">
          <div
            class="vertical-item text-center service-layout-1 content-padding p-3"
            style="border: 3px solid #3e1e68; border-radius: 6px"
            @click="
              $router.push({
                name: 'orders',
              })
            "
          >
            1875 Baydin Answers
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-12">
          <div
            class="vertical-item text-center service-layout-1 content-padding p-3"
            style="border: 3px solid #3e1e68; border-radius: 6px"
            @click="
              $router.push({
                name: 'newyearOrder',
              })
            "
          >
            New Year Baydin Answers
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-12">
          <div
            class="vertical-item text-center service-layout-1 content-padding p-3"
            style="border: 3px solid #3e1e68; border-radius: 6px"
            @click="
              $router.push({
                name: 'Questions',
              })
            "
          >
            Questions
          </div>
        </div>
      </div>
    </div>
  </section>
</template> -->

<template>
  <section class="mt-3">
    <div class="p-2">
      <h4 class="title mb-2" align="center">သင် ၃လအတွင်းမေးထားခဲ့ဖူးသောဟောစာတမ်းများ</h4>

      <div class="row m-0 p-0">
        <div class="container">
          <div
            v-for="(order, index) in orders" :key="index"
            class="card p-2"
            style="
              border-color: #eb6224 !important;
              border-radius: 20px !important;
              margin-bottom: 10px;
            "
          >
            <div class="order__card col-md-12 col-12 col-lg-12 col-xl-12 subscribe">
              <div class="baydin_title d-flex my-2">
                Service Name : <span>{{getServiceName(order.category_name)}}</span>
              </div>
              <div class="baydin_date d-flex mb-2">
                Price : {{order.amount}}MMK
              </div>
              <div class="baydin_day d-flex mb-2">Date : {{ moment(order.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</div>
              <div class="d-flex" style="width: 100%;">
                <div
                    @click="
                      $router.push({
                        name: 'ordersDetails',
                        params: {
                          orderId: order.order_id,
                          serviceName: getServiceName(order.category_name),
                          price: order.amount,
                          date: order.createdAt,
                          categoryId: order.category_id,
                          userId: userId,
                        },
                      })
                    "
                    class="btn"
                >မေးခွန်းကြည့်ရန်</div>
                <div
                    @click="
                      $router.push({
                        name: 'answer',
                        params: {
                          orderId: order.order_id,
                          serviceName: getServiceName(order.category_name),
                          price: order.amount,
                          date: order.createdAt,
                          categoryId: order.category_id,
                          userId: userId,
                        },
                      })
                    "
                    class="btn"
                >အဖြေကြည့်ရန်</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Popup :isVisible="showPopup" @close="closePopup" />
  </section>
</template>
<script>
import {HTTP} from "@core/lib/http-common";
import {mapGetters} from "vuex";
import Popup from '../UI/Popup.vue';

export default {
  name: 'OrderByCategory',
  components: {
    Popup
  },
  data() {
    return {
      categories: {
        tarot_shuffle: "တစ်နေ့တာ တားရော့ကံကြမ္မာ",
        compatability_love: "ဖူးစာဖက်",
        tarot: "တားရော့",
        newyearbaydin: "တစ်နှစ်စာ အဟော",
        clairvoyance: "အကြားအမြင်",
        chiromancy: "လက္ခဏာ",
        child: "အမည်ပေး(ရင်သွေးအမည်)",
        love: "အချစ်ဇာတာဟောစာတမ်း",
        phone: "ဗေဒင်ဆရာမှဖုန်းပြန်လည်ခေါ်ဆိုပေးမည်",
      },
      orders: [],
      userId: null,
      showPopup: false,
      hardcodedValue: false
    };
  },
  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse"
    })
  },
  async created() {
    try {
      if(this.getUserToken && this.getResponse) {
        let openID = this.getResponse?.customer_info?.openID;
        if (!openID) {
          console.error("OpenID is missing in the response!");
          return;
        }
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        const response = await HTTP.get(`subscription/orders_new/openID=${openID}`);
        this.orders = response.data.order;
        this.userId = response.data.user_id;
        // console.log(response.data.order);
      }
    }catch (error) {
      console.log(error);
    }
  },
  methods: {
    getServiceName(category_name) {
      return this.categories[category_name] || category_name;
    },
    handleAnswerClick() {
      if (this.hardcodedValue) {
        this.showPopup = true;
      } else {
        this.$router.push({
          name: 'answer',
          params: {
            orderId: this.orders[0].order_id,
            serviceName: this.getServiceName(this.orders[0].category_name),
            price: this.orders[0].amount,
            date: this.orders[0].createdAt,
            categoryId: this.orders[0].category_id,
            userId: this.userId
          },
        });
      }
    },
    closePopup() {
      this.showPopup = false;
    }
  }
};
</script>

<style scoped>
.order__card {
  min-width: 200px;
  /* padding: 10px; */
  box-sizing: border-box;
  overflow: hidden; /* Prevent overflow */
}

.d-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}

.btn {
  width: auto;
  min-width: 80px;
  height: 20px;
  border-radius: 5.5cm;
  display: inline-flex;
  border: none;
  font-weight: 200;
  font-size: 11px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 5px;
  background: #eb6224;
  padding: 15px 20px; /* Add padding for better appearance */
}

.title {
    font-size: 16px;
    line-height: 20px;
    color: #eb6224;
    font-weight: 300;
}

@media screen and (max-width: 480px) {
  .baydin_title, .baydin_date, .baydin_day {
    font-size: 14px;
  }
}
</style>
