<template>
    <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="entry-content">
                        <div class="woocommerce">
                            <div class="woocommerce-MyAccount-content">
                                <form role="form" id="tarot-form">
                                    <p class="mm-font __mm" align="center">
                                    အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                                    </p>
                                    <div class="form-group">
                                        <span class="mm-font __mm __mm">အမည်</span>
                                        <input
                                            type="text"
                                            class="form-control"
                                            required
                                            v-model="compatability_love.name"
                                            name="name"
                                        />
                                        <div class="error__message" v-if="!compatability_love.name && errors.name">
                                            <i class="pi pi-exclamation-circle mr-1"></i>
                                            {{ errors.name }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <span class="mm-font __mm __mm">ဖုန်းနံပါတ်</span>
                                        <input
                                            type="number"
                                            class="form-control"
                                            required
                                            v-model="compatability_love.phone"
                                            name="name"
                                            placeholder="09xxxxxxxxx"
                                            @input="validateForm"
                                        />
                                        <div class="error__message" v-if="errors.phone">
                                            <i class="pi pi-exclamation-circle mr-1 mb-1"></i>
                                            {{ errors.phone }}
                                        </div>
                                    </div>

                                    <p class="t_c_agreement">By continuing, you agree to <span @click.prevent="redirectTNC()">Terms and Conditions.</span></p>
                                    
                                    <p>2000 ကျပ် ကျသင့်ပါမည်။ <br>အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါရန်။</p>
                                    <p>
                                        <input
                                            @click.prevent="handleSubmit()"
                                            type="submit"
                                            :disabled="disabled"
                                            class="woocommerce-Button button mm-font __mm"
                                            value="အတည်ပြုမည်"
                                        />
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import {checkFormModel, getFormData} from "@core/lib/form-data";
import {Horo, HTTP} from "../../core/lib/http-common";
import {mapGetters} from "vuex";
import {citizenPay, makePayment, onePay} from "../js/payment";
import {EventBus} from "../../public/js/eventBus";
import { validatePhoneNumber } from "../utils/validationPhone";

export default {
        name: "CompatabilityLoveForm",

        data() {
            return {
                disabled: false,
                actionBtnMethod: "",
                compatability_love: {
                    name: "",
                    phone: "",
                    category_id: "3479",
                    category_name: "compatability_love",
                    price: 1000,
                    order_id: "0"
                },
                errors: {},
                isMM: false
            }
        },

        mounted() {
            this.getLocale();
            this.onepayPhoneNumber =
            this.$store.state.paymentName == "onepay"
                ? this.$store.state.onepayPhoneNumber
                : "";
        },

        created() {
            this.actionBtnMethod = this.$store.state.paymentName;
        },

        computed: {
            ...mapGetters({
                getUserToken: "userStore/getUserToken",
                getResponse: "userStore/getResponse",
            }),
        },

        methods: {
            getLocale() {
                window.ma.callNativeAPI("getLocale", {}, res => {
                this.isMM = res.language === "my-MM";
            })
            },

            handleSubmit() {
                this.validateForm();
                let getPaymentName = this.actionBtnMethod;
                switch (getPaymentName) {
                    case "mpitesan":
                        this.makePayment();
                        break;
                    case "onepay":
                        this.onePayProvider();
                        break;
                    case "citizenpay":
                        this.citizenPayProvider();
                        break;
                    case "bppay":
                        this.bpPay();
                        break;
                    default:
                        this.precreate();
                        break;
                }
            },

            redirectTNC() {
                this.$router.push('/category/terms_and_conditions')
            },

            async precreate() {
                if(checkFormModel(this.compatability_love)) {
                    this.disabled = true;
                    let form_data = getFormData(this.compatability_love);
                    HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
                    try {
                        let backend_res = (await HTTP.post("subscription/precreate", {
                            category_id: this.compatability_love.category_id,
                            category_name: this.compatability_love.category_name
                        })).data;

                        form_data.append("payment_method", "KBZPay");
                        form_data.append("srcFrom", "KBZPay");
                        form_data.set("price", backend_res.amount);
                        form_data.set("order_id", backend_res.order_id);

                        let horo_res = (await Horo.post("1875/compatability-love/horoscope", form_data)).data;
                        console.log(horo_res);

                        // new kbzpay mini_app api
                        window.ma.callNativeAPI('startPay', {
                            prepayId: backend_res.prepay_id,
                            orderInfo: backend_res.order_info,
                            sign: backend_res.sign_app,
                            signType: 'SHA256',
                            disableNewCheckout: 'false',
                            tradeType: "MINIAPP"
                            }, res => {
                            console.log("startPay api res: ", res);
                            if (res.resultCode == 1) {
                                EventBus.$emit("clear-cache");
                                if(this.isMM) {
                                    this.$swal(
                                    "အောင်မြင်ပါသည်",
                                    "ဝယ်ယူပြီးပါပြီ။ အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါ။",
                                    "success"
                                    );
                                } else {
                                    this.$swal(
                                    "Success",
                                    "Purchased. Please contact 09456880335 if you have any problems.",
                                    "success"
                                    );
                                }
                                this.$router.push({ name: "Compatability Love" });
                            }else {
                                if(this.isMM) {
                                    this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                                } else {
                                    this.$swal("Fail", "Please try again", "error");
                                }
                                this.disabled = false;
                            }
                        });

                        // this.kbzpay.startPay(
                        //     backend_res.prepay_id,
                        //     backend_res.order_info,
                        //     backend_res.sign_app,
                        //     ({ resultCode }) => {
                        //         if(resultCode == 1) {
                        //             this.$swal("အောင်မြင်ပါသည်", "ဝယ်ယူပြီးပါပြီ။ ဖူးစာဖက်ပက်ကေ့ချ်အား ရယူနိုင်ပါပီ။ အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါရန်။", "success");
                        //             this.$router.push({ name: "Compatability Love" });
                        //         }
                        //         else if(resultCode == 2) {
                        //             this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                        //             this.disabled = false;
                        //         }
                        //     },
                        //     () => {
                        //         this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                        //         this.disabled = false;
                        //     }
                        // )
                    }
                    catch(err) {
                        console.log(err);
                        if(this.isMM) {
                            this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                        } else {
                            this.$swal("Fail", "Please try again", "error");
                        }
                        this.disabled = false;
                    }
                }
                else {
                    if(this.isMM) {
                        this.$swal(
                        "Oops",
                        "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
                        "error"
                        );
                        } else {
                        this.$swal(
                        "Oops",
                        "You need to fill all fields.",
                        "error"
                        );
                        }
                }
            },

            async makePayment() {
                if(checkFormModel(this.compatability_love)) {
                    this.disabled = true;

                    let form_data = getFormData(this.compatability_love);
                    form_data.append("payment_method", 'mpitesan');
                    form_data.append("srcFrom", "MPitesan");
                    let cdata = {
                        category_id: this.compatability_love.category_id,
                        category_name: this.compatability_love.category_name,
                        payment_name: this.actionBtnMethod,
                        phone_number: this.compatability_love.phone,
                    };
                    
                    try {
                        let res = await (await makePayment([
                            "1875/compatability-love/horoscope",
                            form_data,
                            cdata,
                            this.$store.state.backendUrl,
                        ]));

                        if(res.status == 200) {
                            this.$store.state.order_id = res.order_id;
                            this.$store.state.amount = res.amount;
                            if (!res.payment_status) {
                                this.$router.push({
                                    name: "paymentPortal",
                                });
                            } else {
                                this.$router.push({
                                    name: "PaymentSuccess",
                                    params: { payment: "mpitesan" },
                                    query: {category_id: this.compatability_love.category_id}
                                });
                            }
                        }
                        else {
                            throw new Error("Fail");
                        }
                    }
                    catch(err) {
                        if(this.isMM) {
                            this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                        } else {
                            this.$swal("Fail", "Please try again", "error");
                        }
                        this.disabled = false;
                    }
                }
                else {
                    if(this.isMM) {
                        this.$swal(
                        "Oops",
                        "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
                        "error"
                        );
                        } else {
                        this.$swal(
                        "Oops",
                        "You need to fill all fields.",
                        "error"
                        );
                        }
                }
            },

            async onePayProvider() {
                if(checkFormModel(this.compatability_love)) {
                    this.disabled = true;
                    let form_data = getFormData(this.compatability_love);
                    form_data.append("srcFrom", "OnePay");
                    form_data.append("payment_method", "onepay");
                    let cdata = {
                        category_id: this.compatability_love.category_id,
                        category_name: this.compatability_love.category_name,
                        phone_number: this.onepayPhoneNumber || this.compatability_love.phone
                    };

                    try {
                        let res = await onePay([
                            "1875/compatability-love/horoscope",
                            form_data,
                            cdata,
                            this.$store.state.backendUrl,
                        ]);
                        if (res.status == 200) {
                            this.$store.state.order_id = res.order_id;
                            this.$store.state.amount = res.amount;
                        } else {
                            throw new Error('Fail');
                        }
                    }
                    catch(err) {
                        if(this.isMM) {
                            this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                        } else {
                            this.$swal("Fail", "Please try again", "error");
                        }
                    }
                }
                else {
                    if(this.isMM) {
                        this.$swal(
                        "Oops",
                        "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
                        "error"
                        );
                        } else {
                        this.$swal(
                        "Oops",
                        "You need to fill all fields.",
                        "error"
                        );
                        }
                }
            },

            async citizenPayProvider() {
                if(checkFormModel(this.compatability_love)) {
                    this.disabled = true;
                    let form_data = getFormData(this.compatability_love);
                    form_data.append("srcFrom", "CitizenPay");
                    form_data.append("payment_method", "citizenpay");

                    try {
                        let cdata = {
                            category_id: this.compatability_love.category_id,
                            category_name: this.compatability_love.category_name,

                        };
                        let res = await citizenPay([
                            "1875/compatability-love/horoscope",
                            form_data,
                            cdata,
                            this.$store.state.backendUrl,
                        ]);
                        if (res.status == 200) {
                            window.location.href = res.paymentGatway;
                        } else {
                            this.$swal("Oops", "Something went wrong!", "error");
                        }
                    }
                    catch(err) {
                        if(this.isMM) {
                            this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                        } else {
                            this.$swal("Fail", "Please try again", "error");
                        }
                    }
                }
                else {
                    if(this.isMM) {
                    this.$swal(
                    "Oops",
                    "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
                    "error"
                    );
                    } else {
                    this.$swal(
                    "Oops",
                    "You need to fill all fields.",
                    "error"
                    );
                    }
                }
            },

            async bpPay() {
                if(checkFormModel(this.compatability_love)) {
                    this.disabled = true;
                    let form_data = getFormData(this.compatability_love);
                    form_data.append("srcFrom", "PWA BpPay");
                    form_data.append("payment_method", "bppay");

                    let cdata = {
                        category_id: this.compatability_love.category_id,
                        category_name: this.compatability_love.category_name,
                        phone_number: this.compatability_love.phone,
                        payment_name: this.actionBtnMethod,
                    };
                    
                    try {
                        let res = await makePayment([
                            "1875/compatability-love/horoscope",
                            form_data,
                            cdata,
                            this.$store.state.backendUrl,
                        ]);

                        if (res.status == 200) {
                            this.$store.state.order_id = res.order_id;
                            this.$store.state.amount = res.amount;
                            if (!res.payment_status) {
                                    this.$router.push({
                                    name: "paymentPortalV3",
                                });
                            } else {
                                this.$router.push({
                                    name: "PaymentSuccess",
                                    params: { payment: "bppay" },
                                    query: {category_id: this.compatability_love.category_id}
                                });
                            }
                        }
                        else {
                            throw new Error('Fail');
                        }
                    }
                    catch(err) {
                        if(this.isMM) {
                            this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                        } else {
                            this.$swal("Fail", "Please try again", "error");
                        }
                    }
                }
                else {
                    if(this.isMM) {
                        this.$swal(
                        "Oops",
                        "လိုအပ်သည့်အချက်အလက်များ အားလုံးဖြည့်ပေးပါ။",
                        "error"
                        );
                        } else {
                        this.$swal(
                        "Oops",
                        "You need to fill all fields.",
                        "error"
                        );
                        }
                }
            },

            validateForm() {
                this.errors = {};
                if(this.compatability_love.name === '' || !this.compatability_love.name) this.errors.name = 'အမည်ထည့်ရန်လိုအပ်ပါသည်။'
                this.errors.phone = validatePhoneNumber(this.compatability_love.phone)
            },
        }
    }
</script>

<style scoped>
</style>
