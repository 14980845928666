var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mt-3"},[_c('div',{staticClass:"p-2"},[_c('h4',{staticClass:"title mb-2",attrs:{"align":"center"}},[_vm._v("သင် ၃လအတွင်းမေးထားခဲ့ဖူးသောဟောစာတမ်းများ")]),_c('div',{staticClass:"row m-0 p-0"},[_c('div',{staticClass:"container"},_vm._l((_vm.orders),function(order,index){return _c('div',{key:index,staticClass:"card p-2",staticStyle:{"border-color":"#eb6224 !important","border-radius":"20px !important","margin-bottom":"10px"}},[_c('div',{staticClass:"order__card col-md-12 col-12 col-lg-12 col-xl-12 subscribe"},[_c('div',{staticClass:"baydin_title d-flex my-2"},[_vm._v(" Service Name : "),_c('span',[_vm._v(_vm._s(_vm.getServiceName(order.category_name)))])]),_c('div',{staticClass:"baydin_date d-flex mb-2"},[_vm._v(" Price : "+_vm._s(order.amount)+"MMK ")]),_c('div',{staticClass:"baydin_day d-flex mb-2"},[_vm._v("Date : "+_vm._s(_vm.moment(order.createdAt).format("YYYY-MM-DD HH:mm:ss")))]),_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"btn",on:{"click":function($event){_vm.$router.push({
                      name: 'ordersDetails',
                      params: {
                        orderId: order.order_id,
                        serviceName: _vm.getServiceName(order.category_name),
                        price: order.amount,
                        date: order.createdAt,
                        categoryId: order.category_id,
                        userId: _vm.userId,
                      },
                    })}}},[_vm._v("မေးခွန်းကြည့်ရန်")]),_c('div',{staticClass:"btn",on:{"click":function($event){_vm.$router.push({
                      name: 'answer',
                      params: {
                        orderId: order.order_id,
                        serviceName: _vm.getServiceName(order.category_name),
                        price: order.amount,
                        date: order.createdAt,
                        categoryId: order.category_id,
                        userId: _vm.userId,
                      },
                    })}}},[_vm._v("အဖြေကြည့်ရန်")])])])])}),0)])]),_c('Popup',{attrs:{"isVisible":_vm.showPopup},on:{"close":_vm.closePopup}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }