<template>
    <div class="popup-overlay p-4" v-if="isVisible">
      <div class="popup-content">
        <p>Please wait for the answer:)</p>
        <p>Your request is being processed as soon as possible:)</p>
        <button @click="closePopup" class="popup__close">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      closePopup() {
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  }
  
  .popup__close {
    margin-top: 10px;
    padding: 9px 20px !important;
    border-radius: 5px !important;
    background-color: #eb6224 !important;
    border: 1px solid #eb6224 !important;
  }
  </style>